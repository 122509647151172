import Vue from 'vue'

import Home from '../views/Home.vue'
import VueRouter from 'vue-router'
import CompanySelect from '../views/Company_select.vue'
import Payment from '../views/payment/Payment.vue'
import PaymentMethod from '../views/payment/Payment_method.vue'
import BankTransfer from '../views/Bank_transfer'
import ForgetPassword from '../views/Forget_password'
import ChangePassword from '../views/Change_password'
import ToVerscan from '../views/To_verscan.vue'
import Packages from '../views/Packages'
import Manual from '../views/Manual.vue'
import PaymentConfirm from '../views/payment/PaymentConfirm.vue'
import PaymentSuccess from '../views/Payment_success.vue'
// import PrivacyPolicy from '../views/Terms/Privacy.vue'
import PrivacyPolicy from '../views/Terms/PrivacyPDPA.vue'
import Terms from '../views/Terms/Terms.vue'
import DataDeletion from '../views/Terms/DataDelete.vue'
import SLA from '../views/Terms/SLA.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      metaTags: [
        {
          name: 'description',
          content:
            'ระบบตรวจนับสินทรัพย์ ใช้งานง่ายผ่านสมาร์ทโฟน มาพร้อมสติ๊กเกอร์ QR Code สำเร็จรูปสำหรับใช้สแกนตรวจนับ สะดวก รวดเร็ว ไม่ต้องพรินต์เอง',
        },
        { property: 'og:url', content: 'https://verscan.com/' },
        { property: 'og:type', content: 'website' },
        { property: 'og:title', content: 'VERSCAN | A new way to verify the fixed assets' },
        {
          property: 'og:description',
          content:
            'ระบบตรวจนับสินทรัพย์ ใช้งานง่ายผ่านสมาร์ทโฟน มาพร้อมสติ๊กเกอร์ QR Code สำเร็จรูปสำหรับใช้สแกนตรวจนับ สะดวก รวดเร็ว ไม่ต้องพรินต์เอง',
        },
        { property: 'og:image', content: '/ogBanner.png' },
      ],
    },
  },
  // {
  //   path: '/home/package',
  //   name: 'Home',
  //   component: Home,
  // },
  {
    path: '/packages',
    name: 'Packages',
    component: Packages,
    meta: {
      metaTags: [
        {
          name: 'description',
          content:
            'ระบบตรวจนับสินทรัพย์ ใช้งานง่ายผ่านสมาร์ทโฟน มาพร้อมสติ๊กเกอร์ QR Code สำเร็จรูปสำหรับใช้สแกนตรวจนับ สะดวก รวดเร็ว ไม่ต้องพรินต์เอง',
        },
        { property: 'og:url', content: 'https://verscan.com/packages' },
        { property: 'og:type', content: 'website' },
        { property: 'og:title', content: 'VERSCAN | A new way to verify the fixed assets' },
        {
          property: 'og:description',
          content:
            'ระบบตรวจนับสินทรัพย์ ใช้งานง่ายผ่านสมาร์ทโฟน มาพร้อมสติ๊กเกอร์ QR Code สำเร็จรูปสำหรับใช้สแกนตรวจนับ สะดวก รวดเร็ว ไม่ต้องพรินต์เอง',
        },
        { property: 'og:image', content: '/ogBanner.png' },
      ],
    },
  },
  {
    path: '/packages/upgrade',
    name: 'Upgrade',
    component: Packages,
  },
  {
    path: '/toverscan',
    name: 'ToVerscan',
    component: ToVerscan,
  },
  {
    path: '/forget',
    name: 'ChangePassword',
    component: ChangePassword,
  },
  {
    path: '/home',
    name: 'Home',
    component: Home,
    meta: {
      metaTags: [
        {
          name: 'description',
          content:
            'ระบบตรวจนับสินทรัพย์ ใช้งานง่ายผ่านสมาร์ทโฟน มาพร้อมสติ๊กเกอร์ QR Code สำเร็จรูปสำหรับใช้สแกนตรวจนับ สะดวก รวดเร็ว ไม่ต้องพรินต์เอง',
        },
        { property: 'og:url', content: 'https://verscan.com/' },
        { property: 'og:type', content: 'website' },
        { property: 'og:title', content: 'VERSCAN | A new way to verify the fixed assets' },
        {
          property: 'og:description',
          content:
            'ระบบตรวจนับสินทรัพย์ ใช้งานง่ายผ่านสมาร์ทโฟน มาพร้อมสติ๊กเกอร์ QR Code สำเร็จรูปสำหรับใช้สแกนตรวจนับ สะดวก รวดเร็ว ไม่ต้องพรินต์เอง',
        },
        { property: 'og:image', content: '/ogBanner.png' },
      ],
    },
  },
  {
    path: '/companyselect',
    name: 'Companyselect',
    component: CompanySelect,
  },

  {
    path: '/payment',
    name: 'Payment',
    component: Payment,
  },
  {
    path: '/register/payment',
    name: 'RegisterPayment',
    component: Payment,
  },
  {
    path: '/paymentConfirm',
    name: 'PaymentConfirm',
    component: PaymentConfirm,
  },
  {
    path: '/register/paymentConfirm',
    name: 'RegisterPaymentConfirm',
    component: PaymentConfirm,
  },
  {
    path: '/paymentmethod',
    name: 'Paymentmethod',
    component: PaymentMethod,
  },

  {
    path: '/banktransfer',
    name: 'BankTransfer',
    component: BankTransfer,
  },
  {
    path: '/forgetpassword',
    name: 'ForgetPassword',
    component: ForgetPassword,
  },
  {
    path: '/manual',
    name: 'Manual',
    component: Manual,
  },
  {
    path: '/paymentsuccess',
    name: 'PaymentSuccess',
    component: PaymentSuccess,
  },
  {
    path: '/paymentsuccess/trial',
    name: 'TrialPaymentSuccess',
    component: PaymentSuccess,
  },
  {
    path: '/policy',
    name: 'Policy',
    component: PrivacyPolicy,
  },
  {
    path: '/terms',
    name: 'Terms',
    component: Terms,
  },
  {
    path: '/datadeletion',
    name: 'DataDelete',
    component: DataDeletion,
  },
  {
    path: '/sla',
    name: 'SLA',
    component: SLA,
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 }
  },
})
router.beforeEach((to, from, next) => {
  if (to.path.includes('/check')) {
    // console.log('to')
    // console.log(to)
    window.location.replace(`https://app.verscan.com${to.fullPath}`)
  } else {
    next()
  }
})

router.beforeEach((to, from, next) => {
  // This goes through the matched routes from last to first, finding the closest route with a title.
  // e.g., if we have `/some/deep/nested/route` and `/some`, `/deep`, and `/nested` have titles,
  // `/nested`'s will be chosen.
  const nearestWithTitle = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.title)

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.metaTags)

  const previousNearestWithMeta = from.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.metaTags)

  // If a route with a title was found, set the document (page) title to that value.
  if (nearestWithTitle) {
    const nearestTitle = nearestWithTitle.meta.metaTags.find((tag) => tag.property === 'og:title').content
    document.title = nearestWithTitle.meta.title ?? nearestTitle
  } else if (previousNearestWithMeta) {
    const previousTitle = previousNearestWithMeta.meta.metaTags.find((tag) => tag.property === 'og:title').content
    document.title = previousNearestWithMeta.meta.title?? previousTitle;
  }

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map((el) =>
    el.parentNode.removeChild(el)
  )

  // Skip rendering meta tags if there are none.
  if (!nearestWithMeta) return next()

  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags
    .map((tagDef) => {
      const tag = document.createElement('meta')

      Object.keys(tagDef).forEach((key) => {
        tag.setAttribute(key, tagDef[key])
      })

      // We use this to track which meta tags we create so we don't interfere with other ones.
      tag.setAttribute('data-vue-router-controlled', '')

      return tag
    })
    // Add the meta tags to the document head.
    .forEach((tag) => document.head.appendChild(tag))

  next()
})
export default router
